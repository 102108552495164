import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  SaveButton,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _INPUT_FIELD } from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import { useSingleQuery } from '../../utils/hooks/reactQuery/queries';
import useBreakpoint from '../../utils/hooks/useBreakpoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons';

const InputFieldSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  type: Yup.string().required('Type is required'),
  required: Yup.boolean(),
  options: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Key is required'),
      value: Yup.string().required('Value is required'),
    }),
  ),
});

function InputField() {
  const { id } = useParams();
  const {
    data = _INPUT_FIELD,
    isLoading,
    isError,
    error,
  } = useSingleQuery('input-field', id);
  const { update, add } = useItemMutation('input-field');
  const breakpoint = useBreakpoint();

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving input field...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved input field!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save input field...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved input field!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save input field...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  const renderOptions = (values, errors, isSubmitting) => {
    // Check which type is selected
    switch (values.type) {
      case 'Select':
      case 'Select-Custom':
        return (
          <Row>
            <Col className="mb-3">
              <p className="mb-2 p-0">Options</p>
              <FieldArray id="options" name="options">
                {({ push, remove }) => (
                  <>
                    {values.options &&
                      values.options.length > 0 &&
                      values.options.map((option, index) => (
                        <Col key={index} className="border rounded mb-3 p-3">
                          <Row>
                            <BootstrapTextInput
                              key={`key-${index}`}
                              label="Key"
                              id={`options[${index}].key`}
                              name={`options[${index}].key`}
                              placeholder="Key"
                            />
                            <BootstrapTextInput
                              key={`value-${index}`}
                              label="Value"
                              id={`options[${index}].value`}
                              name={`options[${index}].value`}
                              placeholder="Value"
                            />
                            <Col>
                              <Button
                                className={`${
                                  breakpoint === 'xs' || breakpoint === 'sm'
                                    ? 'w-100'
                                    : 'float-end'
                                }`}
                                color="cpred-500"
                                key={`remove-${index}`}
                                disabled={isSubmitting}
                                onClick={() => remove(index)}
                              >
                                <FontAwesomeIcon icon={faTrash} />{' '}
                                <span className="ms-2">Remove</span>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    <Button
                      block
                      color="cpblue-500"
                      disabled={isSubmitting}
                      onClick={() => push({ key: '', value: '' })}
                    >
                      <FontAwesomeIcon icon={faPlus} />{' '}
                      <span className="ms-2">Add</span>
                    </Button>
                    {typeof errors.options === 'string' ? (
                      <div className="small text-danger">
                        <strong>{errors.options}</strong>
                      </div>
                    ) : null}
                  </>
                )}
              </FieldArray>
            </Col>
          </Row>
        );
      default:
        return <div />;
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/input-fields"
          breadcrumbLabel="Input Fields"
          breadcrumbItem={data.name.length > 0 ? data.name : 'New Input Field'}
        >
          <Formik
            initialValues={data}
            validationSchema={InputFieldSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values, errors }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <TypeaheadSingleInput
                    fullWidth
                    label="Type"
                    id="type"
                    name="type"
                    placeholder="Type"
                    options={[
                      'Plain-Text',
                      'Rich-Text',
                      'Select',
                      'Select-Custom',
                      'Image',
                      'Bool',
                    ]}
                  />
                </Row>
                {renderOptions(values, errors, isSubmitting)}
                <Row>
                  <BootstrapToggleInput
                    label="Required"
                    id="required"
                    name="required"
                    labelChecked="This field is required."
                    labelUnchecked="This field is optional."
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default InputField;
