import React, { useEffect, useState, useMemo } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  faFaceParty,
  faLoader,
  faPaperPlane,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  BootstrapDateTimeInput,
  FormCard,
  TypeaheadSingleInput,
  ImageUploadInputFormik,
} from '../components/forms';
import ResponsiveIconSubmitButton from '../components/ResponsiveIconSubmitButton';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  buildSmsTemplate,
  getSmsName,
  createBlueshiftSmsTemplate,
  sendBlueshiftOneTimeSms,
} from '../utils/sms';

const DEFAULT_SUBJECT = 'TD+ Alerts: ';

function UpdateContentState() {
  const { values, setFieldValue, validateForm } = useFormikContext();

  useEffect(() => {
    switch (values.mediaUrl.length) {
      case 0:
        if (!values.message.startsWith(DEFAULT_SUBJECT)) {
          setFieldValue('message', DEFAULT_SUBJECT + values.message, true);
        }
        break;
      default:
        if (values.message.startsWith(DEFAULT_SUBJECT)) {
          setFieldValue(
            'message',
            values.message.replace(DEFAULT_SUBJECT, ''),
            true,
          );
        }
        break;
    }
    if (values.waterfall) {
      setFieldValue('waterfallMessage', values.message, true);
    }
    validateForm();
    // eslint-disable-next-line
  }, [values.mediaUrl, values.waterfall]);

  return null;
}

const AlertSchema = Yup.object().shape({
  message: Yup.string()
    .max(1600, 'Message exceeds max character count')
    .required('Message is required'),
  waterfallMessage: Yup.string()
    .max(1600, 'Message exceeds max character count')
    .when('waterfall', {
      is: (waterfall) => waterfall,
      then: Yup.string().required(
        'Waterfall Message is required when Waterfall is enabled',
      ),
      otherwise: Yup.string(),
    }),
  subject: Yup.string().when('mediaUrl', {
    is: (mediaUrl) => mediaUrl && mediaUrl.length > 0,
    then: Yup.string().required(
      'Subject is required when MMS Image is provided',
    ),
    otherwise: Yup.string(),
  }),
  mediaUrl: Yup.string().url('MMS Image URL should be a valid URL'),
  segmentUuid: Yup.string().required('Segment is required'),
  templateId: Yup.string().required('Template is required'),
  waterfallSegmentUuid: Yup.string().when('waterfall', {
    is: (waterfall) => waterfall,
    then: Yup.string().required(
      'Waterfall Segment is required when Waterfall is enabled',
    ),
    otherwise: Yup.string(),
  }),
  waterfallTemplateId: Yup.string().when('waterfall', {
    is: (waterfall) => waterfall,
    then: Yup.string().required(
      'Waterfall Template is required when Waterfall is enabled',
    ),
    otherwise: Yup.string(),
  }),
  schedule: Yup.date('Schedule must be a date').required(
    'Schedule is required',
  ),
  draftMode: Yup.boolean(),
  waterfall: Yup.boolean(),
});

function TdWatchMarketingMMS() {
  const [alreadySent, setAlreadySent] = useState(false);
  const { data: segments = [], isLoading: segmentsLoading } =
    useAllQuery('bsft-segments');
  const { data: templateFiles = [], isLoading: templatesLoading } =
    useAllQuery('template-files');

  const tdWatchTemplateFile = useMemo(
    () =>
      templateFiles.find(
        (templateFile) =>
          templateFile.name === 'TDWATCH - SMS - US' &&
          templateFile.type === 'SMS',
      ),
    [templateFiles],
  );
  const mtLiveTemplateFile = useMemo(
    () =>
      templateFiles.find(
        (templateFile) =>
          templateFile.name === 'MTLIVE - SMS - US' &&
          templateFile.type === 'SMS',
      ),
    [templateFiles],
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const type = values.mediaUrl ? 'MMS' : 'SMS';
    const toastId = toast.loading(`Sending ${type} message...`);
    const broadcasts = [
      {
        name: 'TDWATCH',
        templateFile: templateFiles.find(
          (templateFile) => templateFile._id === values.templateId,
        ),
        isWaterfall: false,
      },
    ];
    if (values.waterfall) {
      broadcasts.push({
        name: 'MTLIVE',
        templateFile: templateFiles.find(
          (templateFile) => templateFile._id === values.waterfallTemplateId,
        ),
        isWaterfall: true,
      });
    }
    const smsNames = [];
    const data = [];
    const campaignData = [];

    broadcasts.forEach((broadcast) => {
      // Build the SMS Names and Template/Campaign Data
      const smsName = getSmsName(broadcast.name, values.schedule, type);
      const tags = `All_Tags:${broadcast.name},${type}`;
      smsNames.push(smsName);

      const templateData = {
        template: {
          resource: {
            content: buildSmsTemplate({
              template: broadcast.templateFile.content,
              content: broadcast.isWaterfall
                ? values.waterfallMessage
                : values.message,
            }),
            shorten_links: false,
          },
          name: smsName,
          tag_data: tags,
        },
      };

      if (values.mediaUrl) {
        templateData.template.resource.media_url = values.mediaUrl;
        templateData.template.resource.subject = values.subject;
      }

      data.push(templateData);

      campaignData.push({
        send_to_unsubscribed: false,
        triggers: [
          {
            trigger_name: type,
            utm_campaign: smsName,
            utm_source: `${broadcast.name}_${type}`,
            account_adapter_uuid: '539afe76-fa95-4d88-abba-b36bae1e5e6e',
          },
        ],
        name: smsName,
        launch: !values.draftMode,
        startdate: new Date(values.schedule).toISOString(),
        segment_uuid: broadcast.isWaterfall
          ? values.waterfallSegmentUuid
          : values.segmentUuid,
        tag_data: tags,
      });
    });

    try {
      // Create SMS Templates
      const templateResponses = await Promise.allSettled(
        data.map((data) => createBlueshiftSmsTemplate(data)),
      );
      console.log({ templateResponses });
      const templateErrors = templateResponses.filter(
        (response) => response.status === 'rejected',
      );
      if (templateErrors.length > 0) {
        throw new Error(`Could not create ${type} template/s...`);
      }
      const templateValues = templateResponses
        .filter((response) => response.status === 'fulfilled')
        .map((response) => response.value);

      try {
        // Create One-Time Send Campaigns
        const campaignResponses = await Promise.allSettled(
          campaignData.map((data) => {
            // We need to find the template uuid for the campaign and insert it into the data
            const templateValue = templateValues.find(
              (response) => response.name === data.name,
            );
            data.triggers[0].template_uuid = templateValue.uuid;
            return sendBlueshiftOneTimeSms(data);
          }),
        );
        console.log({ campaignResponses });
        const campaignErrors = campaignResponses.filter(
          (response) => response.status === 'rejected',
        );
        if (campaignErrors.length > 0) {
          throw new Error(`Could not create ${type} campaign/s...`);
        }

        toast.update(toastId, {
          render: `Successfully Sent ${type}!`,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        setAlreadySent(true);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: `Could not create ${type} campaign/s...`,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: `Could not create ${type} template/s...`,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
      setSubmitting(false);
    }
  };

  if (segmentsLoading || templatesLoading) {
    return (
      <Container fluid className="text-center">
        <Row>
          <Col>
            <h2>MTA Text Messages</h2>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <FontAwesomeIcon
              icon={faLoader}
              size="9x"
              className="text-cpblue-500"
              spin
            />
          </Col>
        </Row>
      </Container>
    );
  }

  if (alreadySent) {
    return (
      <Container fluid className="text-center">
        <Row>
          <Col>
            <h2>MTA Text Messages</h2>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <FontAwesomeIcon
              icon={faFaceParty}
              size="9x"
              className="text-cpblue-500"
              spin
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Message sent! Please refresh page to send a new message.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="MTA Text Messages"
      >
        <Formik
          initialValues={{
            message: 'YOUR MESSAGE HERE.',
            waterfallMessage: 'YOUR MESSAGE HERE.',
            subject: DEFAULT_SUBJECT,
            mediaUrl: '',
            segmentUuid: '523da035-de5a-4873-8d59-395098a9f276',
            templateId: tdWatchTemplateFile._id,
            waterfallSegmentUuid: '7775b2c2-d0a8-4e03-ac29-5b17eb0d8964',
            waterfallTemplateId: mtLiveTemplateFile._id,
            schedule: '',
            draftMode: false,
            waterfall: false,
          }}
          validationSchema={AlertSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <Row>
                <BootstrapTextInput
                  required
                  fullWidth
                  isTextArea
                  maxLength={values.mediaUrl ? 1600 : 160}
                  label="Message"
                  id="message"
                  name="message"
                  placeholder="Message"
                />
                <ImageUploadInputFormik
                  fullWidth
                  label="MMS Image (Optional)"
                  id="mediaUrl"
                  name="mediaUrl"
                  placeholder="MMS Image"
                />
                {values.mediaUrl && (
                  <BootstrapTextInput
                    required
                    fullWidth
                    label="Subject"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                  />
                )}
                <TypeaheadSingleInput
                  required
                  label="Segment"
                  id="segmentUuid"
                  name="segmentUuid"
                  placeholder="Segment"
                  options={segments}
                  labelKey="name"
                  valueKey="uuid"
                  objects
                  renderMenuItemChildren={(option) => (
                    <div>
                      {option.name}
                      <div>
                        <small>Users: {option.sms_users}</small>
                      </div>
                    </div>
                  )}
                />
                <TypeaheadSingleInput
                  required
                  label="Template"
                  id="templateId"
                  name="templateId"
                  placeholder="Template"
                  options={templateFiles.filter(
                    (template) => template.type === 'SMS',
                  )}
                  labelKey="name"
                  valueKey="_id"
                  objects
                />
                <BootstrapToggleInput
                  fullWidth
                  label="Waterfall"
                  id="waterfall"
                  name="waterfall"
                  labelChecked="Waterfall is enabled"
                  labelUnchecked="Waterfall is disabled"
                />
                {values.waterfall && (
                  <>
                    <BootstrapTextInput
                      required
                      fullWidth
                      isTextArea
                      maxLength={values.mediaUrl ? 1600 : 160}
                      label="Waterfall Message"
                      id="waterfallMessage"
                      name="waterfallMessage"
                      placeholder="Message"
                    />
                    <TypeaheadSingleInput
                      required
                      label="Waterfall Segment"
                      id="waterfallSegmentUuid"
                      name="waterfallSegmentUuid"
                      placeholder="Waterfall Segment"
                      options={segments}
                      labelKey="name"
                      valueKey="uuid"
                      objects
                      renderMenuItemChildren={(option) => (
                        <div>
                          {option.name}
                          <div>
                            <small>Users: {option.sms_users}</small>
                          </div>
                        </div>
                      )}
                    />

                    <TypeaheadSingleInput
                      required
                      label="Template"
                      id="waterfallTemplateId"
                      name="waterfallTemplateId"
                      placeholder="Template"
                      options={templateFiles.filter(
                        (template) => template.type === 'SMS',
                      )}
                      labelKey="name"
                      valueKey="_id"
                      objects
                    />
                  </>
                )}
                <BootstrapDateTimeInput
                  required
                  fullWidth
                  label="Schedule"
                  id="schedule"
                  name="schedule"
                />
                <BootstrapToggleInput
                  fullWidth
                  label="Draft Mode"
                  id="draftMode"
                  name="draftMode"
                  labelChecked="This message will be put in draft mode"
                  labelUnchecked="This message will go live at the scheduled time"
                />
              </Row>
              <ResponsiveIconSubmitButton
                disabled={isSubmitting || !isValid}
                icon={faPaperPlane}
              >
                Send
              </ResponsiveIconSubmitButton>
              <UpdateContentState />
            </Form>
          )}
        </Formik>
      </FormCard>
    </Container>
  );
}

export default TdWatchMarketingMMS;
