import axios from 'axios';
import { apiUrl } from './url';
import { getJwt } from './jwt';
import { getFormattedDateTimeString } from './dateTimeFormat';

export const buildSmsTemplate = ({
  template,
  options = [],
  fromName = '',
  emailTemplateUuid = '',
  itemCode = '',
  webPostPermalink = '',
  content = '',
}) => {
  // First let's grab our current template
  let smsTemplate = template;

  // Static values we always try to inject
  smsTemplate = smsTemplate.replace('$friendly-from', fromName);
  smsTemplate = smsTemplate.replace('$email-template-uuid', emailTemplateUuid);
  smsTemplate = smsTemplate.replace('$item-code', itemCode);
  smsTemplate = smsTemplate.replace('$web-post-permalink', webPostPermalink);
  smsTemplate = smsTemplate.replace('$content', content);

  // Next we will iterate through all of our options and place their values in the template
  for (let option of options) {
    // look for $option-key and replace with option.value
    smsTemplate = smsTemplate.replace(`$${option.key}`, option.value);
  }

  // Done! Let's return our template complete with all options and content!
  return smsTemplate;
};

export const getSmsName = (itemCode, schedule, type) =>
  `${getFormattedDateTimeString(
    false,
    new Date(schedule),
  )}_${itemCode}_${type}`;

export const createBlueshiftSmsTemplate = async (data) => {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJwt()}`,
    },
  };
  try {
    const response = await axios.post(
      `${apiUrl}blueshift/templates/sms`,
      data,
      axiosConfig,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendBlueshiftOneTimeSms = async (data) => {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJwt()}`,
    },
  };

  try {
    const response = await axios.post(
      `${apiUrl}blueshift/campaigns/one-time`,
      data,
      axiosConfig,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
