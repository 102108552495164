import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Row } from 'reactstrap';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import {
  BootstrapTextInput,
  DraftailRichTextInput,
  ImageUploadInputFormik,
  TypeaheadWithCustomInput,
  TypeaheadMultiInput,
  TypeaheadSingleInput,
  BootstrapToggleInput,
} from './forms';

function UpdateOptionsState({ setOptions, setIsValid }) {
  const { values, validateForm, isValid } = useFormikContext();

  useEffect(() => {
    let options = [];
    for (const [key, value] of Object.entries(values)) {
      options.push({ key, value });
    }
    setOptions(options);
    validateForm();
  }, [values]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  return null;
}

function BroadcastOptionsForm({
  inputFields,
  options,
  setOptions,
  wordPress,
  showWebFields,
  setIsValid,
}) {
  const fallback = { Objectives: [], Psychologies: [], Sectors: [] };
  const { data: wordPressTaxonomies = fallback } = useAllQuery(
    'wp-global-taxonomies',
  );

  const [optionsForm, setOptionsForm] = useState(null);

  const validationSchema = useMemo(() => {
    if (inputFields.length > 0) {
      let validationSchema = {};
      inputFields.forEach((field) => {
        const fieldName = field.name.toLowerCase().replace(/\s/g, '-');
        if (field.required) {
          validationSchema[fieldName] = Yup.string().required(
            field.name + ' is required',
          );
        }
      });
      if (wordPress) {
        validationSchema['wordpress-objectives'] = Yup.string().required(
          'Objective is required',
        );
        validationSchema['wordpress-psychologies'] = Yup.string().required(
          'Psychology is required',
        );
        validationSchema['wordpress-sectors'] = Yup.array()
          .of(Yup.string())
          .min(1, 'At least one Sector is required')
          .max(3, 'Maximum of 3 Sectors')
          .required('Sectors is required');
      }
      return Yup.object().shape(validationSchema);
    } else return Yup.object().shape({});
  }, [inputFields]);

  useEffect(() => {
    if (options.length > 0) {
      let optionsAsFormObject = {};
      options.forEach((option) => {
        optionsAsFormObject[option.key] = option.value || '';
      });
      setOptionsForm(optionsAsFormObject);
    }
  }, [options]);

  const renderOptions = () => {
    return inputFields.map((field) => {
      const fieldName = field.name.toLowerCase().replace(/\s/g, '-');
      switch (field.type) {
        case 'Plain-Text':
          return (
            <BootstrapTextInput
              key={field._id}
              label={field.name}
              id={fieldName}
              name={fieldName}
              placeholder={field.name}
              required={field.required}
            />
          );
        case 'Select':
          return (
            <TypeaheadSingleInput
              key={field._id}
              label={field.name}
              id={fieldName}
              name={fieldName}
              placeholder={field.name}
              options={field.options || []}
              objects
              valueKey="value"
              labelKey="key"
              required={field.required}
            />
          );
        case 'Select-Custom':
          return (
            <TypeaheadWithCustomInput
              key={field._id}
              label={field.name}
              id={fieldName}
              name={fieldName}
              placeholder={field.name}
              options={field.options || []}
              objects
              valueKey="value"
              labelKey="key"
              required={field.required}
            />
          );
        case 'Image':
          return (
            <ImageUploadInputFormik
              key={field._id}
              id={fieldName}
              name={fieldName}
              label={field.name}
              placeholder={field.name}
              required={field.required}
            />
          );
        case 'Rich-Text':
          return (
            <DraftailRichTextInput
              key={field._id}
              id={fieldName}
              name={fieldName}
              label={field.name}
              required={field.required}
            />
          );
        case 'Bool':
          return (
            <BootstrapToggleInput
              key={field._id}
              label={field.name}
              id={fieldName}
              name={fieldName}
              labelChecked="True"
              labelUnchecked="False"
            />
          );
        default:
          return <h4 key={field._id}>{field.name + ' ' + field.type}</h4>;
      }
    });
  };

  const renderWordPressOptions = () => {
    return (
      <Row>
        <TypeaheadSingleInput
          label="Objectives"
          id="wordpress-objectives"
          name="wordpress-objectives"
          placeholder="Objectives"
          options={wordPressTaxonomies.Objectives || []}
          required
        />
        <TypeaheadSingleInput
          label="Psychologies"
          id="wordpress-psychologies"
          name="wordpress-psychologies"
          placeholder="Psychologies"
          options={wordPressTaxonomies.Psychologies || []}
          required
        />
        <TypeaheadMultiInput
          label="Sectors"
          id="wordpress-sectors"
          name="wordpress-sectors"
          placeholder="Sectors"
          options={wordPressTaxonomies.Sectors || []}
          required
        />
        <ImageUploadInputFormik
          id="wordpress-featured-image"
          name="wordpress-featured-image"
          label="Featured Image"
          placeholder="Featured Image"
          wordPress={wordPress}
        />
      </Row>
    );
  };

  return (
    <>
      {optionsForm && (
        <Formik
          initialValues={optionsForm}
          validationSchema={validationSchema}
          enableReinitialize={false}
          onChange={() => console.log('Form values changing!')}
        >
          {() => (
            <Form>
              <Row>{renderOptions()}</Row>
              {wordPress && showWebFields && renderWordPressOptions()}
              <UpdateOptionsState
                setOptions={setOptions}
                setIsValid={setIsValid}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default BroadcastOptionsForm;
